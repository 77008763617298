import { isMobile, isTablet } from "react-device-detect";
import "./messenger.scss";
import { Avatar, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { getChats } from "services/SauceService";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import EmptyItems from "assets/images/empty-item.png";
import { Chat } from "types/chat";

interface MessengerProps {
  // Add any props you need here
}

const Messenger: React.FC<MessengerProps> = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        setIsLoading(true);
        const response = await getChats();
        //@ts-ignore
        setChats(response.chats);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchChats();
  }, []);

  return (
    <div className="messenger">
      <Typography.Title>Your messages</Typography.Title>
      <div
        className="card"
        style={{
          marginBottom: isMobile ? 100 : isTablet ? 50 : 0,
        }}
      >
        {isLoading && (
          <div className="box-item" style={{ justifyContent: "center" }}>
            <div className="item">
              <LoadingOutlined />
            </div>
          </div>
        )}
        {!isLoading && chats.length === 0 && (
          <div className="box-item" style={{ justifyContent: "center" }}>
            <div className="item" style={{ textAlign: "center" }}>
              <div className="image-container" style={{ marginBottom: 20 }}>
                <img src={EmptyItems} alt="" />
              </div>
              <span style={{ fontSize: 22, fontWeight: 500 }}>
                No chats yet
              </span>
              <span style={{ fontSize: 16 }}>Your chat history is empty</span>
            </div>
          </div>
        )}
        {!isLoading &&
          chats.map((chat) => (
            <Row>
              <div
                className={`box-item ${chat.unreadCount === 0 ? "faded" : ""}`}
              >
                <div className="icon" style={{}}>
                  {chat.participants.slice(0, 2).map((participant, index) => (
                    <div
                      style={{
                        marginLeft: Math.max(15 * index * -1, -30),
                        zIndex: index + 1,
                      }}
                    >
                      <Avatar size={30} src={participant.avatarUrl} />
                    </div>
                  ))}
                </div>
                <div
                  className="item"
                  onClick={() => navigate(`/messenger/${chat._id}`)}
                >
                  <span className="title">{chat.context.title}</span>
                </div>
              </div>
            </Row>
          ))}
      </div>
    </div>
  );
};

export default Messenger;
