import { BookingAvailability } from "./booking-availability";
// import { Deliverables } from "./deliverables";
// import { FoodieBooking } from "./foodie-booking";
import { Venue } from "./venue";

export enum NotQualifiedReason {
  minimumFollowers = 0,
  banned = 1,
}

export interface Collab {
  collabId: string;
  venue: Venue;
  // deliverables: Deliverables;
  // brief: string;
  // gender: string;
  // focusPoints: string;
  // compensation: string;
  startDate: string;
  endDate: string;
  bookingAvailability: BookingAvailability[];
  slotSizeMinutes: number;
  // timeline: string;
  // status: number;
  // numberOfFoodiesRemaining: number;
  // foodieBookings: FoodieBooking[];
  // categories: string[];
  // basicFoodiesNeeded: number;
  // basicFoodiesRemaining: number;
  // premiumFoodiesNeeded: number;
  // premiumFoodiesRemaining: number;
  // minFollowersAmount: number;
  // aboutBrand: string;
  // maxMilesDistance: number;
  // compensationFood: string;
  // foodComp: number;
  cashCompAmount: number;
  // // diff props
  foodCompAmount: number;
  distanceInMiles: number;
  totalSpotTaken: number;
  totalSpotNeeded: number;
  totalApplications: number;
  guestAllowed: boolean;
  ghostKitchen: boolean;
  spotId: string;
  isQualified: boolean;
  minimumFollowers: number;
  notQualifiedReason: NotQualifiedReason;
}
