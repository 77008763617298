import { Layout, Menu } from "antd";
import { ReactComponent as CollabsIcon } from "assets/images/collabs-icon.svg";
import { ReactComponent as FindCollabsIcon } from "assets/images/find-icon.svg";
import { ReactComponent as EarningsIcon } from "assets/images/earnings-icon.svg";
import { ReactComponent as ProfileIcon } from "assets/images/profile-icon.svg";
import { ReactComponent as MessengerIcon } from "assets/images/notifications-icon.svg";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { useNavigate } from "react-router-dom";
// import { DownOutlined } from "@ant-design/icons";
// import { Footer } from "antd/es/layout/layout";
// import { logout } from "helpers/auth-functions";
import "./Layout.scss";
import { useSelector } from "react-redux";
import { ProfileStatus, UserProfile } from "interfaces/user-profile";
import { autenticado } from "helpers/auth-functions";
// import { isDesktop, isTablet } from "react-device-detect";
// import { useState } from "react";
const { Content, Sider } = Layout;

const DefaultLayout: React.FC<any> = ({ children, showMenu = true }) => {
  // const location = useLocation();
  const navigate = useNavigate();

  const { backgroundColor } = useSelector((state: any) => state.page);

  const { loggedUser }: { loggedUser: UserProfile } = useSelector(
    (state: any) => state.user
  );
  // console.log(page, "page");
  // const onClickLogout = () => {
  //   // logout();
  //   navigate("/login");
  // };

  // const getSelectedMenuByPath = (menu: string) => {
  //   const path = location.pathname;
  //   if ((path === "/" && menu === "collabs") || (path === "/" && menu === "/"))
  //     return "selected";
  //   if (path === "/") return "";

  //   if (menu.includes(path)) {
  //     return "selected";
  //   }
  //   return "";
  // };

  return (
    <Layout>
      <Layout
        style={{
          width: "100%",
          borderTop: 0,
          borderBottom: 0,
        }}
        className="layout"
      >
        {autenticado() && (
          <div
            style={{
              display:
                loggedUser.profileStatus !== ProfileStatus.Active
                  ? "none"
                  : "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "1px solid #E6E6E6",
              padding: "48px 32px",
            }}
          >
            <Logo />
            <Sider style={{ marginTop: 32 }}>
              <Menu
                // selectedKeys={[selectedKey]}
                mode="inline"
                // onClick={(evt) => setSelectedKey(evt.key)}
                // onChange={(evt) => {
                //   console.log(evt, "Event");
                //   dispatch(getPendingCollabsCount());
                // }}
                items={[
                  {
                    key: "collabs",
                    icon: <CollabsIcon />,
                    label: "Collabs",
                    onClick: () => navigate("/"),
                  },
                  {
                    key: "find-collabs",
                    icon: <FindCollabsIcon />,
                    label: "Find Collabs",
                    onClick: () => navigate("/find-collabs"),
                  },
                  {
                    key: "earnings",
                    icon: <EarningsIcon />,
                    label: "Earnings",
                    onClick: () => navigate("/earnings"),
                  },
                  {
                    key: "profile",
                    icon: <ProfileIcon />,
                    label: "Profile",
                    onClick: () => navigate("/profile"),
                  },
                  // {
                  //   key: "Messenger",
                  //   icon: <MessengerIcon />,
                  //   label: "Messenger",
                  //   onClick: () => navigate("/messenger"),
                  // },
                ]}
              />
            </Sider>
          </div>
        )}
        <Content
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: backgroundColor,
          }}
        >
          <div style={{ width: 550, display: "flex" }}>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
