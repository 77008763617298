import { Avatar } from "antd";
import { Chat } from "types/chat";

export const MustardMessage = ({
  content,
  state,
  sender,
}: Chat["messages"][number]) => {
  return (
    <div
      style={{
        display: "flex",
        wordBreak: "break-word",
        gap: 10,
        marginBottom: 10,
        flexDirection: "row",
        marginLeft: 10,
        marginRight: 10,
      }}
    >
      <div>
        <Avatar src={sender.avatarUrl} />
      </div>
      <div
        className="card"
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {content}
        <div
          style={{
            fontSize: 12,
            color: "#888",
            marginTop: 5,
            textAlign: "left",
          }}
        >
          {sender.name}
        </div>
      </div>
    </div>
  );
};
