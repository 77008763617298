import "./messenger.scss";
import { Button, Input } from "antd";
import { getChat, sendMessage, markChatAsRead } from "services/SauceService";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import LoadingFullScreen from "pages/login/LoadingFullScreen";
import { UserProfile } from "interfaces/user-profile";
import { useSelector } from "react-redux";
import { LeftOutlined } from "@ant-design/icons/lib/icons";
import { isMobile } from "react-device-detect";
import { FoodieMessage } from "./components/FoodieMessage";
import { BusinessMessage } from "./components/BusinessMessage";
import { MustardMessage } from "./components/MustardMessage";
import { Chat } from "types/chat";

const createDummyMessage = ({
  content,
  loggedUser,
}: {
  content: string;
  loggedUser: UserProfile;
}) => {
  return {
    content,
    messageType: "text",
    state: "sending",
    senderType: "foodie",
    sender: {
      _id: loggedUser._id,
      name: loggedUser.name,
      avatarUrl: loggedUser.avatarUrl,
    },
    _id: Date.now().toString(),
    createdAt: new Date(),
  };
};

const MessengerChat: React.FC = () => {
  const {
    loggedUser,
  }: {
    loggedUser: UserProfile;
  } = useSelector((state: any) => state.user);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { id } = useParams();
  const [chat, setChat] = useState<Chat | null>(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const pollId = useRef<NodeJS.Timeout | null>(null);

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, 0);
  };

  const markAsRead = useCallback(async (chatId: string) => {
    if (!chatId) return;

    console.log("marking as read");
    try {
      await markChatAsRead(chatId);
    } catch (error) {
      // ignore
    }
  }, []);

  const refetchChat = useCallback(
    async ({
      shouldShowLoading = true,
      shouldScrollToBottom = true,
      abortController,
    }: {
      shouldShowLoading?: boolean;
      shouldScrollToBottom?: boolean;
      abortController?: AbortController;
    }) => {
      if (!id) return;

      console.log("fetching chat");

      try {
        if (shouldShowLoading) setLoading(true);
        const response = await getChat(id, abortController);

        if (!pollId.current) {
          console.trace("not applying chat: polling not present");
          return;
        }

        //@ts-ignore
        setChat(response);
        markAsRead(id);
        if (shouldScrollToBottom) scrollToBottom();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [id, markAsRead]
  );

  const handleSendMessage = async () => {
    if (!chat || isSendingMessage || !message || message.trim().length === 0) {
      return;
    }

    const originalMessage = message;
    setMessage("");

    try {
      stopPolling();
      setIsSendingMessage(true);

      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          createDummyMessage({
            content: originalMessage,
            loggedUser,
          }) as Chat["messages"][number],
        ],
      });

      scrollToBottom();

      const messageFromServer = await sendMessage(chat._id, {
        message: originalMessage,
      });
      setChat({
        ...chat,
        //@ts-ignore
        messages: [...chat.messages, messageFromServer],
      });
    } catch (error) {
      // console.error(error);
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          {
            ...(createDummyMessage({
              content: originalMessage,
              loggedUser,
            }) as Chat["messages"][number]),
            state: "error",
          },
        ],
      });
    } finally {
      setIsSendingMessage(false);
      scrollToBottom();
      startPolling();
    }
  };

  const startPolling = useCallback(() => {
    if (pollId.current) return;

    console.trace("polling started");
    pollId.current = setInterval(() => {
      refetchChat({
        shouldScrollToBottom: false,
        shouldShowLoading: false,
      });
    }, 5_000);
  }, [refetchChat]);

  const stopPolling = useCallback(() => {
    if (!pollId.current) return;
    console.trace("polling stopped");
    clearInterval(pollId.current);
    pollId.current = null;
  }, [pollId]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!chat && id) {
      getChat(id, abortController)
        .then((res) => {
          //@ts-ignore
          setChat(res);
        })
        .then(() => {
          setLoading(false);
          scrollToBottom();
          startPolling();
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      stopPolling();
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFullScreen />;

  if (!chat) return <div>Chat not found</div>;

  return (
    <div className="messenger chat">
      <div className="header">
        <div onClick={() => navigate("/messenger")}>
          <LeftOutlined style={{ cursor: "pointer" }} />
        </div>
        <div className="title-container">
          <span className="title">{chat.context.title}</span>
        </div>
      </div>

      <div className="content-container">
        <div ref={messagesContainerRef} className="messages-container">
          {chat.messages.map((message) => (
            <>
              {message.senderType === "foodie" && (
                <FoodieMessage key={message._id} {...message} />
              )}
              {message.senderType === "business" && (
                <BusinessMessage key={message._id} {...message} />
              )}
              {message.senderType === "mustard-on-behalf-of-business" && (
                <BusinessMessage key={message._id} {...message} />
              )}
              {message.senderType === "mustard" && (
                <MustardMessage key={message._id} {...message} />
              )}
              {![
                "foodie",
                "business",
                "mustard",
                "mustard-on-behalf-of-business",
              ].includes(message.senderType) && (
                <>
                  <div>Type: {message.senderType}</div>
                  <div className="card">{message.content}</div>
                </>
              )}
            </>
          ))}
        </div>

        <div
          className="input-container"
          style={{
            marginBottom: isMobile ? 84 : 0,
          }}
        >
          <Input.TextArea
            style={{ height: 50 }}
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            type="primary"
            shape="round"
            size="large"
            disabled={isSendingMessage}
            onClick={handleSendMessage}
            loading={isSendingMessage}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MessengerChat;
