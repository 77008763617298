import { TikTokFilled } from "@ant-design/icons";
import { Button } from "antd";

const { REACT_APP_INSTAGRAM_TIKTOK_CLIENT_KEY, REACT_APP_CLIENT_URL } =
  process.env;
const redirectUri = `${REACT_APP_CLIENT_URL}tiktok-auth`;

const TikTokButton: React.FC<{ asLabel?: boolean }> = (props) => {
  const { asLabel } = props;
  const handleLogin = async () => {
    let url = "https://www.tiktok.com/v2/auth/authorize/";

    let array = new Uint8Array(30);
    crypto.getRandomValues(array);
    const csrfState = Array.from(array)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    const encoder = new TextEncoder();
    const data = encoder.encode(csrfState);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    url += `?client_key=${REACT_APP_INSTAGRAM_TIKTOK_CLIENT_KEY}`;
    url += "&scope=user.info.basic,user.info.profile,user.info.stats";
    url += "&response_type=code";
    url += `&redirect_uri=${redirectUri}`;
    url += "&state=" + csrfState;
    url += "&code_challenge=" + hash;
    url += "&code_challenge_method=S256";
    window.location.href = url;
  };

  if (asLabel) {
    return (
      <div className="media" style={{ cursor: "pointer" }}>
        <TikTokFilled />
        <span onClick={handleLogin}>
          <b>Please connect your TikTok</b>
        </span>
      </div>
    );
  }

  return (
    <Button
      style={{ borderRadius: 12 }}
      icon={<TikTokFilled />}
      onClick={handleLogin}
    />
  );
};

export default TikTokButton;
