import { Form, Input, Button, notification, message } from "antd";
import {
  UserOutlined,
  // LockOutlined,
  LinkOutlined,
  PhoneFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
// import FacebookButton from "components/facebook-button/FacebookButton";
import TikTokButton from "components/tiktok-buttom/TikTokButton";
import {
  generateMagicLink,
  sendVerificationCode,
  signInPhone,
} from "services/SauceService";
// import InputMask from "react-input-mask";
import VerificationInput from "react-verification-input";
import InstagramButton from "components/instagram-button/InstagramButton";

enum LoginType {
  EMAIL = "email",
  PHONE = "phone",
  MAGIC_LINK = "magic-link",
}

enum Step {
  Phone = "phone",
  Code = "code",
}

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [loginType, setLoginType] = useState<LoginType>(LoginType.EMAIL);
  const [step, setStep] = useState<Step>(Step.Phone);
  // const dispatch = useDispatch<any>();

  const [form] = Form.useForm();

  const onFinish = async () => {
    setLoading(true);
    const values = form.getFieldsValue(true);
    if (loginType === LoginType.MAGIC_LINK) {
      try {
        const resposne = await generateMagicLink(values.email);
        console.log(resposne);
        message.success("Magic link sent ✨🪄✨");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        api.error({
          message: "Error",
          description: "Invalid email",
        });
      }
    }
    if (loginType === LoginType.PHONE) {
      if (step === Step.Phone) {
        const values = form.getFieldsValue(true);
        const params = {
          contact: values.phone,
          type: "phone",
        };

        await sendVerificationCode(params);
        setStep(Step.Code);
        setLoading(false);
      }
      if (step === Step.Code) {
        const values = form.getFieldsValue(true);
        const params = {
          phone: values.phone,
          code: values.verificationCode,
        };
        try {
          const response: any = await signInPhone(params);

          if (response?.accessToken) {
            console.log("response", response);
            navigate("/signin-link?accessToken=" + response.accessToken);
          }
        } catch {
          setLoading(false);
          message.error("Invalid code");
        }
      }
    }
  };

  const handleLoginMagicLink = () => {
    setLoginType(LoginType.MAGIC_LINK);
  };

  const handleLoginPhone = () => {
    setLoginType(LoginType.PHONE);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "20vh",
        height: "100vh",
      }}
    >
      {contextHolder}

      <Form form={form} name="login" onFinish={onFinish} style={{ width: 300 }}>
        {loginType === LoginType.EMAIL && (
          <div style={{ textAlign: "center" }}>
            <h2>Login</h2>
            {/* <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Sign In
              </Button>
            </Form.Item>

            <Row justify="center" style={{ margin: "12px 0" }}>
              <span>Or</span>
            </Row> */}
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <FacebookButton /> */}
              <TikTokButton />
              <Button
                style={{ borderRadius: 12 }}
                icon={<LinkOutlined />}
                onClick={handleLoginMagicLink}
              />
              <Button
                style={{ borderRadius: 12 }}
                icon={<PhoneFilled />}
                onClick={handleLoginPhone}
              />
              <InstagramButton />
            </div>
          </div>
        )}
        {loginType === LoginType.MAGIC_LINK && (
          <div>
            <h2>Magic Link</h2>
            <Form.Item
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Send Magic Link
              </Button>
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button type="link" onClick={() => setLoginType(LoginType.EMAIL)}>
                Back
              </Button>
            </div>
          </div>
        )}
        {loginType === LoginType.PHONE && step === Step.Phone && (
          <div>
            <h2>Phone</h2>
            <Form.Item
              name="phone"
              rules={[{ required: true, message: "Please input your phone!" }]}
            >
              <Input
                prefix={<PhoneFilled />}
                placeholder="Phone"
                style={{ width: "100%" }}
              />
              {/* <InputMask mask="(999) 999-9999" maskChar=" " alwaysShowMask>
                {() => <Input prefix={<PhoneFilled />} placeholder="Phone" />}
              </InputMask> */}
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Get verification code
            </Button>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button type="link" onClick={() => setLoginType(LoginType.EMAIL)}>
                Back
              </Button>
            </div>
          </div>
        )}
        {loginType === LoginType.PHONE && step === Step.Code && (
          <div>
            <h2>Code</h2>
            <Form.Item
              name="verificationCode"
              rules={[
                {
                  required: true,
                  message: "Please input the verification code!",
                },
              ]}
            >
              <VerificationInput
                validChars="0-9"
                inputProps={{
                  inputMode: "numeric",
                  autoComplete: "one-time-code",
                }}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Send verification code
            </Button>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                type="link"
                onClick={() => {
                  setLoginType(LoginType.EMAIL);
                  setStep(Step.Phone);
                }}
              >
                Back
              </Button>
            </div>
          </div>
        )}

        {/* <div
          style={{
            marginTop: 20,
            display: "flex",
            gap: 20,
            justifyContent: "center",
          }}
        >
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.mustard.love/privacy-policy"
          >
            privacy policy
          </a>
          <a
            style={{ textDecoration: "underline" }}
            href="https://www.mustard.love/terms-of-service"
          >
            terms of service
          </a>
        </div> */}
      </Form>
    </div>
  );
};

export default Login;
